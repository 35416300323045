import {PaymentRequestSettingsService} from './../../services/paymentRequest/PaymentRequestSettingsService';
import {PaymentRequestService} from './../../services/paymentRequest/PaymentRequestService';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/src/types/PaymentsWidgetConfiguration';
import {getCurrentLanguage} from '../../utils/ambassador.utils';
import {PaymentRequestPaymentStoreProps} from '../../../types/paymentRequestApp.types';

export type PaymentStoreConfig = {
  flowAPI: ControllerFlowAPI;
  paymentRequestService: PaymentRequestService;
  paymentRequestSettingsService: PaymentRequestSettingsService;
  isSSR: boolean;
  updateComponent: () => void;
};

export class PaymentRequestPaymentStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly paymentRequestService: PaymentRequestService;
  private readonly paymentRequestSettingsService: PaymentRequestSettingsService;
  private readonly isSSR: boolean;
  private readonly updateComponent: () => void;

  constructor({
    flowAPI,
    updateComponent,
    paymentRequestService,
    paymentRequestSettingsService,
    isSSR,
  }: PaymentStoreConfig) {
    this.flowAPI = flowAPI;
    this.paymentRequestService = paymentRequestService;
    this.paymentRequestSettingsService = paymentRequestSettingsService;
    this.isSSR = isSSR;
    this.updateComponent = updateComponent;
  }

  public toProps(): PaymentRequestPaymentStoreProps {
    const currentLanguage = getCurrentLanguage(this.flowAPI);
    const configuration: PaymentsWidgetConfiguration = {
      locale: this.flowAPI.translations.config.language,
      appId: this.flowAPI.controllerConfig.appParams.appDefinitionId,
      appInstanceId: this.flowAPI.controllerConfig.appParams.instanceId,
      amount: this.paymentRequestService.paymentRequest.total.amount.toString(),
      currency: this.paymentRequestService.paymentRequest.currency as string,
      msid: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId as string,
      appInstance: this.flowAPI.controllerConfig.appParams.instance,
      visitorId: this.flowAPI.controllerConfig.platformAPIs.bi?.visitorId,
      siteOwnerId: this.flowAPI.controllerConfig.platformAPIs.bi?.ownerId,
      lang: this.flowAPI.translations.config.language,
      viewMode: 'Site',
      isPrimaryLanguage: currentLanguage?.isPrimaryLanguage ?? true,
      companyName: this.paymentRequestSettingsService.paymentRequestSettings.storeName,
    };

    return {
      cashierWidgetProps: {
        configuration,
        locale: this.flowAPI.translations.config.language,
        theme: 'default',
        shouldApplySiteStyles: false,
        externalSubmitButton: true,
        walletPaymentInNewTab: false,
        allowManualPayment: false,
        allowInPersonPayment: false,
      },
      cashierConfiguration: configuration,
      isSSR: this.isSSR,
    };
  }
}
